import If from "@/components/common/If";
import { AudioLines, HammerIcon, Leaf, Lock, Music, ShieldCheck, ShowerHead } from "lucide-react";
import Link from "next/link";
import { Button } from "@/components/actions/Button";
import Image from "@/components/common/Image";
import { Tooltip } from "@/components/ui/Tooltip";
import RichText from "@/components/common/RichText";
interface CardProps {
  title: string;
  description: string;
  image?: string;
  className?: string;
  showButton?: boolean;
  href?: string;
  linkText?: string;
  showResources?: boolean;
  isLight?: boolean;
  imageCover?: boolean;
  showSolutions?: boolean;
  solutions?: any;
  cardType?: string;
  subtitle?: string;
}
const Card: React.FC<CardProps> = ({
  title,
  description,
  image,
  className = "",
  showButton = true,
  showSolutions = true,
  href = "",
  linkText = "Learn More",
  showResources = true,
  isLight = false,
  imageCover = true,
  solutions = [],
  cardType = "Default",
  subtitle = ""
}) => {
  return <article className={`flex-card ${!imageCover ? "px-[2rem]" : ""} ${className} stack`} data-sentry-component="Card" data-sentry-source-file="Card.tsx">
      <If condition={image} data-sentry-element="If" data-sentry-source-file="Card.tsx">
        <Image src={image} alt={title}
      // className={`w-full object-cover h-[600px] aspect-[1/1] mix-blend-multiply`}
      imgClassName={`w-full ${cardType === "Icon" ? "h-[80px]" : "h-[600px] max-h-[50dvh]"} aspect-[1/1]`} cover={imageCover} maxWidth="1000" data-sentry-element="Image" data-sentry-source-file="Card.tsx" />
      </If>
      <If condition={!image} data-sentry-element="If" data-sentry-source-file="Card.tsx">
        <div className="bg-gray-200 aspect-[1/1] w-full mb-4"></div>
      </If>
      <div className={`py-4 stack gap-6 flex-1 justify-between ${!imageCover ? "" : "px-[2rem]"}`}>
        <div className="stack gap-6">
          <div className="stack gap-3">
            <If condition={showSolutions} data-sentry-element="If" data-sentry-source-file="Card.tsx">
              <div className="flex gap-4 opacity-40">
                <If condition={solutions?.includes("Durability")} data-sentry-element="If" data-sentry-source-file="Card.tsx">
                  <Link href="/solutions/durability" data-sentry-element="Link" data-sentry-source-file="Card.tsx">
                    <Tooltip text="Durability" data-sentry-element="Tooltip" data-sentry-source-file="Card.tsx">
                      <HammerIcon size={24} data-sentry-element="HammerIcon" data-sentry-source-file="Card.tsx" />
                    </Tooltip>
                  </Link>
                </If>
                <If condition={solutions?.includes("Safety")} data-sentry-element="If" data-sentry-source-file="Card.tsx">
                  <Link href="/solutions/safety" data-sentry-element="Link" data-sentry-source-file="Card.tsx">
                    <Tooltip text="Safety" data-sentry-element="Tooltip" data-sentry-source-file="Card.tsx">
                      <ShieldCheck size={24} data-sentry-element="ShieldCheck" data-sentry-source-file="Card.tsx" />
                    </Tooltip>
                  </Link>
                </If>
                <If condition={solutions?.includes("Security")} data-sentry-element="If" data-sentry-source-file="Card.tsx">
                  <Link href="/solutions/security" data-sentry-element="Link" data-sentry-source-file="Card.tsx">
                    <Tooltip text="Security" data-sentry-element="Tooltip" data-sentry-source-file="Card.tsx">
                      <Lock size={24} data-sentry-element="Lock" data-sentry-source-file="Card.tsx" />
                    </Tooltip>
                  </Link>
                </If>
                <If condition={solutions?.includes("Acoustics")} data-sentry-element="If" data-sentry-source-file="Card.tsx">
                  <Link href="/solutions/acoustics" data-sentry-element="Link" data-sentry-source-file="Card.tsx">
                    <Tooltip text="Acoustics" data-sentry-element="Tooltip" data-sentry-source-file="Card.tsx">
                      <AudioLines size={24} data-sentry-element="AudioLines" data-sentry-source-file="Card.tsx" />
                    </Tooltip>
                  </Link>
                </If>
                <If condition={solutions?.includes("Cleanability")} data-sentry-element="If" data-sentry-source-file="Card.tsx">
                  <Link href="/solutions/cleanability" data-sentry-element="Link" data-sentry-source-file="Card.tsx">
                    <Tooltip text="Cleanability" data-sentry-element="Tooltip" data-sentry-source-file="Card.tsx">
                      <ShowerHead size={24} data-sentry-element="ShowerHead" data-sentry-source-file="Card.tsx" />
                    </Tooltip>
                  </Link>
                </If>
              </div>
            </If>
            <If condition={subtitle} data-sentry-element="If" data-sentry-source-file="Card.tsx">
              <h3 className="text-sm text-gray" dangerouslySetInnerHTML={{
              __html: subtitle?.replace("Aspiro", "Aspiro<sup>&reg;</sup>")?.replace("Cendura", "Cendura<sup>&reg;</sup>")
            }} />
            </If>
            <h2 className={`text-[2rem] fon-normal mb-2 ${cardType === "Icon" ? "text-center" : "min-h-[2em]"}`}>
              {title}
            </h2>
            <If condition={cardType !== "Icon"} data-sentry-element="If" data-sentry-source-file="Card.tsx">
              <div className="bg-gold h-[5px] w-[35px]" />
            </If>
          </div>
          <If condition={description?.json} data-sentry-element="If" data-sentry-source-file="Card.tsx">
            <div className={`text-gray-600  overflow-hidden text-body ${cardType === "Icon" ? "text-center" : ""}`}>
              <RichText json={description?.json} data-sentry-element="RichText" data-sentry-source-file="Card.tsx" />
            </div>
          </If>
        </div>
        <If condition={showButton && href} data-sentry-element="If" data-sentry-source-file="Card.tsx">
          <div className="flex stack gap-8">
            <Button href={href} isLight={isLight} data-sentry-element="Button" data-sentry-source-file="Card.tsx">
              {linkText || "Learn More"}
            </Button>
          </div>
        </If>
      </div>
    </article>;
};
export default Card;